body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sticky-action-column {
  position: sticky;
  right: 0;
  background-color: white; /* Ensure the background is consistent */
  z-index: 10; /* Make sure it stays on top */
  border-left: 1px solid #ddd; /* Optional: Add a border to separate the sticky column */
}

.ag-theme-alpine {
  overflow: hidden;
}

.ag-theme-alpine .ag-center-cols-container {
  overflow-x: auto;
}
